export default {
  data() {
    return {
      formRules: {
        requiredField: [(v) => !!v || "Campo obligatorio"],
        numberRules: [(v) => !v || v >= 0 || "Introduce un valor positivo"],
        numberRulesAllowZero: [(v) => v !== "" || "Campo obligatorio", (v) => v >= 0 || "Introduce un valor positivo"],
        passwordRequiredRules: [(v) => v.length >= 6 || "La contraseña debe tener al menos 6 caracteres"],
        passwordNotRequiredRules: [(v) => v.length === 0 || v.length >= 6 || "La contraseña debe tener al menos 6 caracteres"],
        emailRules: [(v) => v.length === 0 || /^\w+[\w-.]*@\w+((-\w+)|(\w*))\.[a-z]{2,10}$/.test(v) || "Introduce un Email válido"],
        emailRequiredRules: [
          (v) => !!v || "Campo obligatorio",
          (v) => /^\w+[\w-.]*@\w+((-\w+)|(\w*))\.[a-z]{2,10}$/.test(v) || "Introduce un Email válido"
        ],
        phoneRules: [(v) => !!v || "Campo obligatorio", (v) => /^\d+$/.test(v) || "Este capo debe ser únicamente numérico"],
        docRules: [(v) => !!v || "Campo obligatorio", (v) => !v || v.size < 1000000 || "El documento debe pesar menos de 1MB"],
        imageRequiredRules: [(v) => !!v || "Campo obligatorio", (v) => !v || v.size < 2000000 || "La imagen debe pesar menos de 2MB"],
        imageNotRequiredRules: [(v) => !v || v.size < 2000000 || "La imagen debe pesar menos de 2MB"]
      }
    };
  }
};
