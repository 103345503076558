<template>
  <v-card class="title-card pa-6 pa-md-10 mb-12 rounded-xl">
    <h2 class="mb-4">{{ title }}</h2>
    <slot name="content" />
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
.title-card {
  border: 6px solid #00f9ad;
}
</style>
